<template>
  <a-select
    v-model:value="messageTypeSelected"
    :getPopupContainer="(trigger) => trigger.parentNode"
    class="custom-select"
    mode="multiple"
    style="width: 100%"
    :options="messageTypeOptions"
    @change="onMsgTypeSelect"
  >
  </a-select>
  <!-- <a-select class="detail-select" v-model:value="messageTypeSelected" mode="tags" style="width: 100%">
    <template #dropdownRender="{}">
      <a-checkbox-group
        class="checkbox-custom vertical"
        v-model:value="messageTypeSelected"
        name="checkboxgroup"
        :options="messageTypeOptions"
        @change="onChange"
      />
    </template>
  </a-select> -->
</template>

<script>
import { ref, onMounted } from 'vue';

const defaultMessageTypeSelected = ['origin', 'comment'];
const defaultMessageTypeOptions = [
  { label: 'Origin', value: 'origin' },
  { label: 'Comment', value: 'comment' },
];
export default {
  emits: ['onChangeMessageType'],
  setup(props, { emit }) {
    const messageTypeSelected = ref([...defaultMessageTypeSelected]);
    const messageTypeOptions = defaultMessageTypeOptions;

    const onMsgTypeSelect = () => {
      emit('onChangeMessageType', messageTypeSelected.value);
    };

    onMounted(() => {
      // init first time
      emit('onChangeMessageType', messageTypeSelected.value);
    });

    return {
      onMsgTypeSelect,
      messageTypeOptions,
      messageTypeSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  :deep(.ant-select-selector) {
    min-height: 42px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
  }

  :deep(.ant-select-selection-item) {
    background: rgba(240, 245, 255, 1);
    border-radius: 5px;
    color: rgba(90, 95, 125, 1);
    font-weight: 700;
  }
}
</style>
